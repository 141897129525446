<template>
	<b-col cols="12" class="p-0">
		<b-card>
			<ValidationObserver>
				<form @submit.prevent="saveItem">
					<b-row>
						<b-col cols="12">
							<div class="form-group">
								<label>Tahun *<span class="tooltip_hint" id="hint-1">?</span></label>
								<validation-provider
									name="Year"
									rules="required"
									v-slot="{ errors, classes }"
								>
									<input 
										type="text" 
										class="form-control" 
										:class="classes"
										placeholder="2019"
										v-model="formPtkp.year"
										:state="errors.length > 0 ? false:null"
									>
									<small
										v-for="(validation, index) in validations.year"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
								<b-tooltip target="hint-1" triggers="hover">
									Tahun mulai berlaku penghasilan tidak kena pajak tsb
								</b-tooltip>
							</div>
						</b-col>
						<b-col cols="12">
							<div class="form-group">
								<label>Jumlah Tanggungan *</label>
								<validation-provider
									name="Year"
									rules="required"
									v-slot="{ errors }"
								>
									<b-form-select
										v-model="formPtkp.tax_liability"
										:options="options"
										@change="changeLiability"
										:state="errors.length > 0 ? false:null"
									/>
									<small
										v-for="(validation, index) in validations.tax_liability"
										:key="`errorName${index}`"
										class="text-danger"
									>{{ validation }}</small>
								</validation-provider>
							</div>
						</b-col>
						<b-col cols="6">
							<b-row>
								<template
									v-for="(item, index) in inputsPtkptk"
								>
									<b-col 
										cols="12" 
										:key="index + 'ptkp_tk'"
									>
										<label>TK/{{ index }} </label>
											<input 
												type="number" 
												class="form-control" 
												placeholder="54000000"
												v-model="item.nominal"
											>
									</b-col>
								</template>
							</b-row>
						</b-col>
						<b-col cols="6">
							<b-row>
								<template
									v-for="(item, index) in inputsPtkpk"
								>
									<b-col 
										cols="12" 
										:key="index + 'ptkp_k'"
									>
										<label>K/{{ index }}</label>
											<input 
												type="number" 
												class="form-control" 
												placeholder="54000000"
												v-model="item.nominal"
											>
									</b-col>
								</template>
							</b-row>
						</b-col>
					</b-row>
					
					<b-col cols="12" class="p-0 mt-2">
						<button
							variant="primary"
							class="btn waves-effect waves-float waves-light btn-primary"
						>
							Save
						</button>
						&nbsp;
						&nbsp;
						<a
							@click="handleBackButton()"
							variant="secondary"
							class="btn waves-effect waves-float waves-light btn-secondary"
						>
							Cancel
						</a>
					</b-col>
				</form>
			</ValidationObserver>
		</b-card>
	</b-col>
</template>

<script>
import {
	BCard,
	BRow, 
	BFormGroup, 
	BFormRadio,  
	BCol,
	BTooltip,
	BFormSelect
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormGroup,
		BFormRadio,
		BTooltip,
		BFormSelect,
		ValidationProvider,
		ValidationObserver
	},

	setup() {
		return {
			successNotification,
			errorNotification,
		}
	},

	data() {
		return{
			required,
			formPtkp: {
				tax_liability: '',
				year: '',
			},
			options:[
				{ value: '', text: '--- Pilih Jumlah Tanggungan ---' },
				{ value: 0, text: '0' },
				{ value: 1, text: '1' },
				{ value: 2, text: '2' },
				{ value: 3, text: '3' },
				{ value: 4, text: '4' },
				{ value: 5, text: '5' },
				{ value: 6, text: '6' },
				{ value: 7, text: '7' },
				{ value: 8, text: '8' },
				{ value: 9, text: '9' },
				{ value: 10, text: '10' },
			],
			inputsPtkptk: [],
			inputsPtkpk: [],
			validations: ''
		}
	},

	methods: {
		handleBackButton() {
			history.back()
		},
		changeLiability(option) {
			this.inputsPtkptk = [{}]
			this.inputsPtkpk = [{}]
			
			for (var i= 0; i < option; i++) {
				this.inputsPtkptk.push({})
				this.inputsPtkpk.push({})
			}
			
		},
		saveItem() {
			let ptkptk = []
			let ptkpk = []
			this.inputsPtkptk.forEach(function (v, i) {
				ptkptk.push(v.nominal)
			})
			this.inputsPtkpk.forEach(function (v, i) {
				ptkpk.push(v.nominal)
			})
			this.formPtkp.variables = {
				ptkpk: ptkpk,
				ptkptk: ptkptk
			}
			this.$http.post('admin/ptkps', this.formPtkp)
			.then(response => {
				successNotification(this, 'Success', 'PTKP sukses dibuat!')
				this.$router.push({ name: 'ptkp' })
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Jumlah Tanggungan', this.validations.tax_liability.join(' '))
					errorNotification(this, 'Tahun', this.validations.year.join(' '))
				}
			})
		}
	}
}
</script>

<style scoped>
	.tooltip_hint {
		border-radius: 50%;
		background: #000;
		color: #fff;
		padding: 0px 4px;
	}
</style>